import React from "react";
import { Link } from "gatsby";

import Button from "./button";

const Header2 = () => {

  return (
    <header className="md:fixed absolute top-0 w-full z-50 bg-white shadow-lg">
      <div className="container mx-auto">
        <div className="flex py-5 justify-between items-center">
          <div className="flex flex-row gap-8 items-center">
            <Link to="/">
              <h1 className="font-display font-medium text-body-lg md:text-display-sm text-neutral-700 whitespace-nowrap">
                TRU COST <span className="font-display text-body-lg md:text-display-sm text-primary-600 italic">Roofing</span>
              </h1>
            </Link>
          </div>
          <div className="flex flex-row gap-6">
            <Button label="Get A Quote" link="/quote"/>
          </div>
        </div>
      </div>
      <div>
        <hr className="text-neutral-300"></hr>
      </div>
    </header>
  );
};

export default Header2;