import React from "react";
import Layout from "../components/layout2";
import { Link } from "gatsby";

const ThanksPage = () => {

  return (
    <Layout>
      <div className="flex justify-center items-center px-8 mt-32">
        <div className="p-12 w-full md:w-3/4 lg:w-2/3 xl:w-1/2 2xl:max-w-6xl shadow-xl rounded-lg bg-white border border-neutral-100">
          <h1 className="text-display-2xl font-display font-bold text-primary-600 mb-6 text-center">
            Thank You!
          </h1>
          <p className="text-body-lg font-medium text-neutral-700 text-center mb-6">
            We will get back to you as soon as possible.
          </p>
          <div className="text-center">
            <Link to="/" className="text-primary-600 hover:text-primary-700 font-semibold text-body-md">
              Return to homepage
            </Link>
          </div>
        </div>  
      </div>
    </Layout>
  );
};

export default ThanksPage;
